<template>
  <footer role="contentinfo" class="mt-40 bg-gray-50 px-64 py-48">
    <div style="grid-area: logo">
      <h2 class="mb-12 font-bold">
        <NuxtLink to="/">
          <SiteLogo />
        </NuxtLink>
      </h2>
    </div>
    <div style="grid-area: contact">
      <h2 class="mb-12 font-bold">
        {{ labelAddress }}
      </h2>
      <p>{{ address }}</p>
    </div>
    <div style="grid-area: cvr">
      <h2 class="mb-12 font-bold">
        {{ labelCvr }}
      </h2>
      <p>{{ cvr }}</p>
    </div>
    <div style="grid-area: desc" v-html="description" />
    <div style="grid-area: menu" class="direction-rtl grid grid-cols-1 gap-10 md:grid-cols-3">
      <div v-for="{menuItemTitle: title, menuItemLinks: items } in menu" :key="title" class="direction-ltr grid auto-rows-min gap-8 md:ml-auto">
        <h2 class="mb-4 font-bold">
          {{ title }}
        </h2>
        <NuxtLink
          v-for="item in items"
          :key="item.href"
          :to="convertToRelativeUrl(item.href)"
          @click="trackClick(item, title)"
        >{{ item.title }}</NuxtLink>
      </div>
    </div>
    <div style="grid-area: spacer">
      <hr>
    </div>
    <div style="grid-area: payment" class="text-center md:text-right ">
      <img v-for="{ url, alt } in icons?.payment || []" :key="url" :src="url" :alt="alt || url" width="32" height="20" class="mx-6 inline-block max-w-[2rem]" loading="lazy">
    </div>
  </footer>
</template>

<script lang="ts" setup>
import type { ContentLayoutFooterData } from '@aller/nuxt-allerservice-sdk/transformers';

const { address: labelAddress, cvr: labelCvr } = useString('footer');
const { address, cvr, description, menu, icons } = useState<ContentLayoutFooterData>('footer').value;

const trackClick = (item, sectionText: string) => {
  useGTM().pushElement('click_footer_item', {
    section_id: 'footer',
    section_text: sectionText,
    element_id: item.contentLink?.guidValue,
    element_text: item.title,
    element_link: item.href,
  });
};
</script>

<style scoped>
footer {
  @apply grid gap-30;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "logo logo"
    "contact  cvr"
    "desc  desc"
    "menu  menu"
    "menu  menu"
    "menu  menu"
    "spacer  spacer"
    "trust trust"
    "payment payment"
    "mark mark";
}

@screen md {
  footer {
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas:
      "logo logo logo logo logo"
      "contact cvr menu menu menu"
      "desc desc menu menu menu"
      "desc desc menu menu menu"
      "spacer spacer spacer spacer spacer"
      "trust trust mark payment payment";
  }
}
</style>
